let ArticleData = [
  {
    title:
      "Análise Da Lateralidade E Destreza Manual Em Crianças Com Transtorno Do Espectro Autista",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/342962267_ANALISE_DA_LATERALIDADE_E_DESTREZA_MANUAL_EM_CRIANCAS_COM_TRANSTORNO_DO_ESPECTRO_AUTISTA",
    data: 2020,
  },
  {
    title:
      " The role of mental practice in decreasing forgetting after practicing a gymnastics motor skill",
    mainAutor: "Barbara de Paula",
    type: "Artigo",
    link: "",
    data: 2020,
  },
  {
    title:
      "The Stimulus’ Orienting Function May Play an Important Role in IRAP Performance: Supportive Evidence from an Eye-Tracking Study of Brands",
    mainAutor: "Joana Andrade",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/339081955_The_Stimulus'_Orienting_Function_May_Play_an_Important_Role_in_IRAP_Performance_Supportive_Evidence_from_an_Eye-Tracking_Study_of_Brands",
    data: 2020,
  },
  {
    title:
      "Motor learning and COMT Val158met polymorphism: Analyses of oculomotor behavior and corticocortical communication",
    mainAutor: "Nathálya Nogueira",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/338480468_Motor_learning_and_COMT_Val158met_polymorphism_Analyses_of_oculomotor_behavior_and_corticocortical_communication",
    data: 2020,
  },
  {
    title:
      "Oculomotor behavior and the level of repetition in motor practice: Effects on pupil dilation, eyeblinks and visual scanning",
    mainAutor: "Lucas Bicalho ",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/330842518_Oculomotor_behavior_and_the_level_of_repetition_in_motor_practice_Effects_on_pupil_dilation_eyeblinks_and_visual_scanning",
    data: 2019,
  },
  {
    title:
      "Association Between the Catechol-O-Methyltransferase (COMT) Val158Met Polymorphism and Manual Performance Asymmetries",
    mainAutor: "Nathálya Nogueira",
    type: "Artigo",
    link: "https://pubmed.ncbi.nlm.nih.gov/30841785/",
    data: 2019,
  },
  {
    title:
      "Association Between Fast and Slow Learning and Molecular Processes in Repetitive Practice: A Post Hoc Analysis",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/337596451_Association_Between_Fast_and_Slow_Learning_and_Molecular_Processes_in_Repetitive_Practice_A_Post_Hoc_Analysis",
    data: 2019,
  },
  {
    title:
      "Molecular Mechanisms Associated with the Benefits of Variable Practice in Motor Learning",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/334615075_Molecular_Mechanisms_Associated_with_the_Benefits_of_Variable_Practice_in_Motor_Learning",
    data: 2019,
  },
  {
    title:
      "Processamento de informações e intervenção do profissional: tomada de decisão em foco",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/332829202_Processamento_de_informacoes_e_intervencao_do_profissional_tomada_de_decisao_em_foco",
    data: 2019,
  },
  {
    title:
      "Self-Controlled Feedback and Learner Impulsivity in Sequential Motor Learning",
    mainAutor: "Barbara de Paula",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/328063683_Self-Controlled_Feedback_and_Learner_Impulsivity_in_Sequential_Motor_Learning",
    data: 2019,
  },
  {
    title: "Influência da prática de natação e da idade na lateralidade.",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link: "",
    data: 2019,
  },
  {
    title:
      "Effects of Mat Pilates and Jacobson’s Relaxation Technique in Low Back Pain",
    mainAutor: "Cristiane Junqueira",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/337667721_Effects_of_Mat_Pilates_and_Jacobson's_Relaxation_Technique_in_Low_Back_Pain",
    data: 2018,
  },
  {
    title:
      "Cathodal tDCS of the Left Posterior Parietal Cortex Increases Proprioceptive Drift ",
    mainAutor: "João Roberto",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/325324935_Cathodal_tDCS_of_the_Left_Posterior_Parietal_Cortex_Increases_Proprioceptive_Drift",
    data: 2018,
  },
  {
    title:
      "Análise da assimetria manual na performance do Grooved Pegboard Test utilizando um acelerômetro",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/328586808_Analise_da_assimetria_manual_na_performance_do_Grooved_Pegboard_Test_utilizando_um_acelerometro",
    data: 2018,
  },
  {
    title:
      "Bench press exercise performed as conditioning activity improves shot put performance in untrained subjects",
    mainAutor: "João Roberto",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/329171492_Bench_press_exercise_performed_as_conditioning_activity_improves_shot_put_performance_in_untrained_subjects",
    data: 2018,
  },
  {
    title:
      "Análise do exercício físico em crianças com transtorno do déficit de atenção e hiperatividade (TDAH): uma revisão integrativa",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/326960975_Analise_do_exercicio_fisico_em_criancas_com_transtorno_do_deficit_de_atencao_e_hiperatividade_TDAH_uma_revisao_integrativa",
    data: 2018,
  },
  {
    title:
      "THE CONTEXTUAL INTERFERENCE EFFECT IN THE LEARNING OF A MANUAL AIMING TASK",
    mainAutor: "Natália Lelis-Torres",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/323074384_The_contextual_interference_effect_in_the_learning_of_a_manual_aiming_task",
    data: 2018,
  },
  {
    title: "Análise da assimetria de força de preensão manual entre os sexos",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/338161430_Analise_da_assimetria_de_forca_de_preensao_manual_entre_os_sexos",
    data: 2018,
  },
  {
    title:
      "O esforço cognitivo inerente à performance musical: conceitos e aplicabilidade",
    mainAutor: "Leonardo Lopes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/314102330_O_esforco_cognitivo_inerente_a_performance_musical_conceitos_e_aplicabilidade",
    data: 2017,
  },
  {
    title:
      "The level of mental workload related to the index of difficulty of the motor task and handedness",
    mainAutor: "Natália Lelis-Torres",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/321794059_The_level_of_mental_workload_related_to_the_index_of_difficulty_of_the_motor_task_and_handedness",
    data: 2017,
  },
  {
    title:
      "Association between the Catechol-O-Methyltransferase (COMT) Val158Met polymorphism and motor behavior in healthy adults: a study review",
    mainAutor: "Nathálya Nogueira",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/330842518_Oculomotor_behavior_and_the_level_of_repetition_in_motor_practice_Effects_on_pupil_dilation_eyeblinks_and_visual_scanning",
    data: 2017,
  },
  {
    title:
      "Motor control assessment of Community-Dwelling older adults with depressive symptoms",
    mainAutor: "Lucas Bicalho ",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/330842518_Oculomotor_behavior_and_the_level_of_repetition_in_motor_practice_Effects_on_pupil_dilation_eyeblinks_and_visual_scanning",
    data: 2017,
  },
  {
    title:
      "Comparison between manual aiming control and sex in different task constraints",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/322923529_Comparison_between_manual_aiming_control_and_sex_in_different_task_constraints",
    data: 2017,
  },
  {
    title:
      "The Influence Of Audience On Sensorymotor Performance In University Students",
    mainAutor: "Simara Ribeiro",
    type: "Artigo",
    link:
      "https://www.scielo.br/scielo.php?script=sci_arttext&pid=S2448-24552017000100107&lng=en&nrm=iso",
    data: 2017,
  },
  {
    title:
      "O conhecimento do professor de educação física sobre aprendizagem motora",
    mainAutor: "Nathálya Nogueira",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/343547217_O_conhecimento_do_professor_de_educacao_fisica_sobre_aprendizagem_motora",
    data: 2017,
  },
  {
    title:
      "Uma análise do desenvolvimento motor de crianças com transtorno do déficit de atenção e hiperatividade (TDAH)",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/316185509_Uma_analise_do_desenvolvimento_motor_de_criancas_com_transtorno_do_deficit_de_atencao_e_hiperatividade_TDAH",
    data: 2017,
  },
  {
    title: "The effect of constant practice in transfer tests",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/313481431_The_effect_of_constant_practice_in_transfer_tests",
    data: 2017,
  },
 
  {
    title:
      "Conhecimento de Graduados e Graduandos em Educação Física sobre o Autismo",
    mainAutor: "Lívia Alípio",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/316147178_Conhecimento_de_Graduados_e_Graduandos_em_Educacao_Fisica_sobre_o_Autismo",
    data: 2016,
  },
  {
    title:
      "Aplicação da estimulação transcraniana por corrente contínua na melhoria do desempenho manual",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/324097013_Aplicacao_da_estimulacao_transcraniana_por_corrente_continua_na_melhoria_do_desempenho_manual",
    data: 2016,
  },
  {
    title:
      "The primary motor cortex is associated with learning the absolute, but not relative, timing dimension of a task: A tDCS study",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/299408148_The_primary_motor_cortex_is_associated_with_learning_the_absolute_but_not_relative_timing_dimension_of_a_task_A_tDCS_study",
    data: 2016,
  },
  {
    title:
      "Movimentos básicos na performance do contrabaixo:descrição e análise cinesiológica",
    mainAutor: "Leonardo Lopes",
    type: "Tese",
    link:
      "http://www.musica.ufmg.br/lapis/wp-content/uploads/2019/02/Leonardo-Lopes-M-2015.pdf",
    data: 2015,
  },
  {
    title:
      "Repetition and variation in motor practice: A review of neural correlates",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/281238061_Title_Repetition_and_variation_in_motor_practice_A_review_of_neural_correlates",
    data: 2015,
  },
  {
    title:
      "Bertram Turetzky's Nancy: Cinesiology and Deliberate Practice of the Arco plus Pizz. Extended Technique on the Double Bass",
    mainAutor: "Leonardo Lopes",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/279321325_Bertram_Turetzky's_Nancy_Cinesiology_and_Deliberate_Practice_of_the_Arco_plus_Pizz_Extended_Technique_on_the_Double_Bass",
    data: 2014,
  },
  {
    title:
      "Association between the Catechol-O-Methyltransferase (COMT) Val158Met Polymorphism and Manual Aiming Control in Healthy Subjects",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/263396800_Association_between_the_Catechol-O-Methyltransferase_COMT_Val158Met_Polymorphism_and_Manual_Aiming_Control_in_Healthy_Subjects",
    data: 2014,
  },
  {
    title:
      "Prevalência de obesidade dos adolescentes sem histórico de atividade física orientada, utilizando o método de índice de massa corporal (IMC)",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link:
      "https://www.efdeportes.com/efd199/prevalencia-de-obesidade-dos-adolescentes.htm",
    data: 2014,
  },
  {
    title:
      "Sex differences in dimensions of impulsivity in a non-clinical sample",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/260678245_Sex_differences_in_dimensions_of_impulsivity_in_a_non-clinical_sample",
    data: 2013,
  },
  {
    title:
      "Nível de Atividade Física de Portadores de Transtorno Metal e de Comportamento",
    mainAutor: "Tércio Apolinário-Souza",
    type: "Artigo",
    link: "https://periodicos.ufv.br/revminef/article/view/10122",
    data: 2013,
  },
  {
    title:
      "A kinematic analysis of manual aiming control on euthymic bipolar disorder",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/232718973_A_kinematic_analysis_of_manual_aiming_control_on_euthymic_bipolar_disorder",
    data: 2012,
  },
  {
    title:
      "A kinematic analysis of the association between impulsivity and manual aiming control",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/221936791_A_kinematic_analysis_of_the_association_between_impulsivity_and_manual_aiming_control",
    data: 2012,
  },
  {
    title:
      "Correlation between impulsivity dimensions and the control in a motor timing task",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/224773921_Correlation_between_impulsivity_dimensions_and_the_control_in_a_motor_timing_task",
    data: 2011,
  },
  {
    title:
      "O Efeito Da Interferência Contextual Na Aprendizagem Motora: Contribuições Científicas Após Três Décadas Da Publicação Do Primeiro Artigo",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/277177244_O_EFEITO_DA_INTERFERENCIA_CONTEXTUAL_NA_APRENDIZAGEM_MOTORA_CONTRIBUICOES_CIENTIFICAS_APOS_TRES_DECADAS_DA_PUBLICACAO_DO_PRIMEIRO_ARTIGO",
    data: 2011,
  },
  {
    title:
      "EFEITOS DA DEMONSTRAÇÃO TÁTIL-CINESTÉSICA NA APRENDIZAGEM DO GOLPE TAI OTOSHI DO JUDÔ EM INDIVÍDUOS COM DEFICIÊNCIA VISUAL: DADOS PRELIMINARES",
    mainAutor: "João Roberto",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/281449799_EFEITOS_DA_DEMONSTRACAO_TATIL-CINESTESICA_NA_APRENDIZAGEM_DO_GOLPE_TAI_OTOSHI_DO_JUDO_EM_INDIVIDUOS_COM_DEFICIENCIA_VISUAL_DADOS_PRELIMINARES",
    data: 2011,
  },
  {
    title: "Impulsivity and the 5-HTTLPR Polymorphism in a Non-Clinical Sample",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/50349006_Impulsivity_and_the_5-HTTLPR_Polymorphism_in_a_Non-Clinical_Sample",
    data: 2011,
  },
  {
    title:
      "A Perspectiva Do Lazer Na Terceira Idade: O Estudo Do Lazer Em Diferentes Grupos Sociais",
    mainAutor: "Lidiane Fernandes",
    type: "Artigo",
    link: "",
    data: 2011,
  },
  {
    title:
      "Correlação entre as dimensões da impulsividade e o controle em uma tarefa motora de timing",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/261026381_Correlacao_entre_as_dimensoes_da_impulsividade_e_o_controle_em_uma_tarefa_motora_de_timing",
    data: 2010,
  },
  {
    title:
      "Assimetrias manuais e complexidade da tarefa em habilidades de apontamento",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/255654153_Assimetrias_manuais_e_complexidade_da_tarefa_em_habilidades_de_apontamento",
    data: 2008,
  },
  {
    title: "Visual and Tactile Information in Double Bass Intonation Control",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/6262465_Visual_and_Tactile_Information_in_Double_Bass_Intonation_Control",
    data: 2007,
  },
  {
    title:
      "The combination of practice schedules: Effects on relative and absolute dimensions of the task",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/282700791_The_combination_of_practice_schedules_Effects_on_relative_and_absolute_dimensions_of_the_task",
    data: 2006,
  },
  {
    title:
      "Practice schedules and number of skills as contextual interference factors in the learning of positioning timing tasks",
    mainAutor: "Guilherme Lage",
    type: "Artigo",
    link:
      "https://www.researchgate.net/publication/289036321_Practice_schedules_and_number_of_skills_as_contextual_interference_factors_in_the_learning_of_positioning_timing_tasks",
    data: 2005,
  },
];
export default ArticleData;
