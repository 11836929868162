import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  root: {
    position: "relative",
  },
  slide: {
    padding: 10,
    color: "#fff",
  },

  image: {
    width: "100%",
    alignSelf: "center",
  },
};

class Carrousel2 extends React.Component {
  state = {
    index: 0,
  };

  handleChangeIndex = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    const { index } = this.state;

    return (
      <React.Fragment>
        <div style={styles.root}>
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
          >
            <div style={Object.assign({}, styles.slide)}>
              <img
                src={require("../Images/Card2F1.jpeg")}
                style={styles.image}
              />
            </div>

            <div style={Object.assign({}, styles.slide)}>
              <img
                src={require("../Images/Card2F2.jpeg")}
                style={styles.image}
              />
            </div>
            <div style={Object.assign({}, styles.slide)}>
              <img
                src={require("../Images/Card2F3.jpg")}
                style={styles.image}
              />
            </div>
            <div style={Object.assign({}, styles.slide)}>
              <img
                src={require("../Images/Card2F4.jpeg")}
                style={styles.image}
              />
            </div>
          </AutoPlaySwipeableViews>
        </div>
      </React.Fragment>
    );
  }
}

export default Carrousel2;
