import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import Event from "../Components/EventCard";
const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "2%",
    fontWeight: "600",
    margin: 20,
    marginBottom: "0",
  },
  image: {
    width: "100%",
    minHeight: 350,
    maxHeight: 500,
  },
  background: {
    backgroundColor: "#ffffff",
  },
  body: {
    display: "flex",
    minHeight: "86vh",
    flexDirection: "column",
  },
}));
function Eventos() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Header />
      <Grid className={classes.body}>
        <Typography className={classes.title} variant="h3">
          Eventos
        </Typography>
        <Event
          title=" 1º Congresso Internacional de Psiquiatria e Psicologia - CiPP"
          data=" Data: 26 a 28 de Novembro"
          desription="
        Entre os palestrantes internacionais já confirmados, estão os doutores Antoine Bechara (EUA), Celia Franco (Portugal), Damien Brevers (Luxemburgo) e Mario Juruena (Inglaterra). Também compõem a programação 16 palestrantes nacionais, entre eles os psiquiatras Dr. Antônio Geraldo da Silva, Dr. Ary Gadelha, Dr. Francisco Assumpção e mais. "
          location="Local: Online"
          price="Valor inicial: R$150 "
          poster={require("../Images/cipp.png")}
          link="https://cursos.institutogenus.com.br/cipp"
        />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Eventos;
