import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  card: {
    paddingLeft: "4%",
  },
  email: {
    paddingTop: "7PX",
  },
  lates: {
    fontWeight: "600",
  },
}));
export default function MemberCard({
  picture,
  name,
  desription,
  interess,
  lates,
  email,
}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Card style={{ minHeight: 185, margin: 10 }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item={true} md={3} sm={3}>
            <CardMedia
              image={picture}
              style={{
                borderRadius: 75,
                height: 150,
                width: 150,
                margin: 10,
                marginTop: 15,
              }}
            />
          </Grid>
          <Grid
            item={true}
            md={9}
            sm={9}
            xs={12}
            container
            justify="center"
            direction="column"
            alignItems="flex-start"
            className={classes.card}
          >
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="subtitle1" component="p">
              {desription}
            </Typography>
            <Typography variant="subtitle1" component="p">
              Área De Pesquisa: {interess}
            </Typography>
            <Grid
              item={true}
              container
              justify="flex-start"
              direction="row"
              style={{
                marginLeft: -7,
              }}
            >
              <Grid item={true} xs={3}>
                <Button
                  onClick={() => {
                    window.open(lates);
                  }}
                >
                  <Typography className={classes.lates}>Lattes</Typography>
                </Button>
              </Grid>
              <Grid item={true} xs={9}>
                <Typography className={classes.email}>
                  Email: {email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
}
