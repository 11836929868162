const Autors = [
  {
    value: "",
    label: "Todos",
  },

  {
    value: "Barbara de Paula",
    label: "Barbara de Paula",
  },
  {
    value: "Cristiane Junqueira",
    label: "Cristiane Junqueira",
  },
  {
    value: "Guilherme Lage",
    label: "Guilherme Lage",
  },
  {
    value: "João Roberto",
    label: "João Roberto",
  },
  {
    value: "Joana Andrade",
    label: "Joana Andrade",
  },
  {
    value: "Lucas Bicalho",
    label: "Lucas Bicalho",
  },
  {
    value: "Leonardo Lopes",
    label: "Leonardo Lopes",
  },
  {
    value: "Lidiane Fernandes",
    label: "Lidiane Fernandes",
  },
  {
    value: "Lívia Alípio",
    label: "Lívia Alípio",
  },
  {
    value: "Natália Lelis-Torres",
    label: "Natália Lelis-Torres",
  },
  {
    value: "Nathálya Nogueira",
    label: "Nathálya Nogueira",
  },
  {
    value: "Simara Ribeiro",
    label: "Simara Ribeiro",
  },
  {
    value: "Tércio Apolinário-Souza",
    label: "Tércio Apolinário-Souza",
  },
];
const ArticleType = [
  {
    value: "",
    label: "Todos",
  },
  {
    value: "Artigo",
    label: "Artigos",
  },
  {
    value: "Tese",
    label: "Teses",
  },
];
const Language = [
  {
    value: "",
    label: "Todos",
  },
  {
    value: "Aprendizagem-Motora",
    label: "Aprendizagem-Motora",
  },
  {
    value: "Desenvolvimento motor",
    label: "Desenvolvimento motor",
  },
];
export { Autors, ArticleType, Language };
