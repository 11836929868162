import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import Carrousel from "../Components/Carrousel";
import { makeStyles } from "@material-ui/core/styles";
import Carrousel2 from "../Components/Carrousel2";
const useStyles = makeStyles((theme) => ({
  text: {
    padding: "5%",
    textAlign: "center",
    marginTop: "-2%",
  },
  email: { paddingLeft: "20%" },
  baseText: { padding: "5%", textAlign: "justify", marginTop: "-2%" },
  listText: { padding: "5%", textAlign: "justify", marginTop: "-9%" },
  title: {
    textAlign: "center",
    marginTop: "2%",
    fontWeight: "bold",
  },
  grid: {
    minHeight: 400,
  },
  gridInside: {
    margin: 40,
  },

  background: {
    backgroundColor: "#ffffff",
  },
}));
function Home() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Header />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item={true} xs={12} md={7}>
          <Carrousel />
        </Grid>
        <Grid item={true} xs={12} md={5}>
          <Typography variant="h5" className={classes.title}>
            Quem somos
          </Typography>
          <Typography variant="subtitle1" className={classes.baseText}>
            O Núcleo de Neurociências do Movimento (NNeuroM) é um núcleo de
            pesquisa do Grupo de Estudo em Desenvolvimento e Aprendizagem Motora
            (GEDAM) da Escola de Educação Física, Fisioterapia e Terapia
            Ocupacional da UFMG. Pronunciasse o nome como “neurom”. Esse núcleo
            foi criado para estudar o controle e a aprendizagem motora em uma
            perspectiva neurobiológica. O estudo do movimento voluntário é
            realizado em diferentes níveis de análises, que passa pelos mais
            macroscópicos, o níveis sociológico e comportamental, até os mais
            microscópicos como o eletrofisiológico e molecular. Para tal, o
            NNeuroM interage com outros laboratórios da UFMG e em outras
            instituições no Brasil e no exterior. Integrantes de diferentes
            áreas de formação participam do núcleo, tais como Educação Física,
            Fisioterapia, Terapia Ocupacional e Música. Criado em 2016, o
            NNeuroM já formou em seus quatros anos de existência (2020): 14
            mestres, 3 doutores e uma supervisão de pós-doutorado.
          </Typography>
          <Typography variant="subtitle1" className={classes.listText}>
            Líder do Grupo: Guilherme Menezes Lage
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item={true} xs={12} md={5}>
          <Typography variant="h5" className={classes.title}>
            Linhas De Pesquisa
          </Typography>
          <Typography variant="subtitle1" className={classes.baseText}>
            1) Associação entre fatores que otimizam a aprendizagem motora e
            aspectos neurobiológicos
          </Typography>
          <Typography variant="subtitle1" className={classes.listText}>
            2) Modulação da resposta central no controle motor
          </Typography>
          <Typography variant="subtitle1" className={classes.listText}>
            3) Papel de áreas cerebrais no controle e aprendizagem motora
          </Typography>
          <Typography variant="subtitle1" className={classes.listText}>
            4) Funções executivas, transtornos psiquiátricos e comportamento
            motor.
          </Typography>
        </Grid>
        <Grid item={true} xs={12} md={7}>
          <Carrousel2 />
        </Grid>
      </Grid>
      <Typography variant="h5" className={classes.title}>
        Fale com a gente!
      </Typography>
      <Grid
        className={classes.grid}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid className={classes.gridInside}>
          <img
            className={classes.email}
            src={require("../Images/Gmail.jpg")}
            alt="Gmail"
          />
          <Typography variant="h5" className={classes.text}>
            nneurom@gmail.com
          </Typography>
        </Grid>
        <Grid
          item={true}
          onClick={() => {
            window.open("https://www.instagram.com/nneurom_ufmg/");
          }}
          className={classes.gridInside}
          md={2}
          sm={3}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <img
            className={classes.image}
            src={require("../Images/Insta.png")}
            alt="Logo"
          />
          <Typography variant="h5" className={classes.text}>
            Instagram
          </Typography>
        </Grid>
        <Grid
          item={true}
          onClick={() => {
            window.open("https://www.facebook.com/NNeuroM");
          }}
          className={classes.gridInside}
          md={2}
          sm={3}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <img
            className={classes.image}
            src={require("../Images/Face.png")}
            alt="Logo"
          />
          <Typography variant="h5" className={classes.text}>
            Facebook
          </Typography>
        </Grid>
        <Grid
          onClick={() => {
            window.open(
              "https://www.youtube.com/channel/UCWK6C4O0w93B5GpsGorvw1A/videos"
            );
          }}
          item={true}
          className={classes.gridInside}
          md={2}
          sm={3}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <img
            className={classes.image}
            src={require("../Images/Youtube.png")}
            alt="Logo"
          />
          <Typography variant="h5" className={classes.text}>
            Youtube
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Home;
