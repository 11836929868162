import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
const Event = ({
  title,
  data,
  desription,
  location,
  price,
  submision,
  poster,
  link,
}) => (
  <React.Fragment>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ padding: 10 }}
    >
      <Grid item={true} xs={12} md={5} style={{ padding: 20 }}>
        <Button
          onClick={() => {
            if (link === "") {
            } else {
              window.open(link);
            }
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              textAlign: "justify",
              textTransform: "capitalize",
              marginLeft: -10,
              marginBottom: 10,
            }}
            variant="h5"
          >
            {title}
          </Typography>
        </Button>
        <Typography style={{ textAlign: "justify" }} variant="subtitle1">
          {desription}
        </Typography>
        <Grid container direction="row" style={{ marginTop: 2 }}>
          <Grid item={true} sm={6} xs={12}>
            <Typography variant="subtitle1">{data}</Typography>
          </Grid>
          <Typography variant="subtitle1">{price}</Typography>
        </Grid>
        <Typography variant="subtitle1">{location}</Typography>
        <Typography
          style={{ textAlign: "justify", fontWeight: 600, marginBottom: 10 }}
          variant="h6"
        >
          {submision}
        </Typography>
      </Grid>
      <Grid
        item={true}
        xs={12}
        md={7}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <img src={poster} style={{ width: "95%" }} />
      </Grid>
    </Grid>
  </React.Fragment>
);
export default Event;
