import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ArticleData from "./ArticleData";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autors, ArticleType, Language } from "./SearchOptions";

const useStyles = makeStyles(() => ({
  table: { width: "100%" },
  input: {
    width: "100%",
    padding: 5,
  },
  grid: {
    width: "100%",
    padding: 20,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  },
  mainGrid: { marginLeft: "0.5%", marginBottom: "2%" },
  authorText: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  titlteText: {
    fontWeight: 600,
    textAlign: "justify",
    textTransform: "capitalize",
    marginLeft: -10,
  },
}));

export default function SearchArticle() {
  const classes = useStyles();
  const [autor, setAutor] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, ArticleData.length - page * rowsPerPage);

  return (
    <React.Fragment>
      <Grid container direction="row">
        <Grid item={true} className={classes.mainGrid} xs={12} sm={4}>
          <TextField
            className={classes.input}
            label="Procure pelo titulo..."
            placeholder=" Procure pelo titulo..."
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
          ></TextField>
        </Grid>
        <Grid item={true} xs={6} sm={2}>
          <TextField
            className={classes.input}
            select
            label="Tipo"
            value={type}
            onChange={(e) => setType(e.target.value)}
            variant="outlined"
          >
            {ArticleType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item={true} xs={12} sm={3} md={2}>
          <TextField
            className={classes.input}
            select
            label="Autor"
            value={autor}
            onChange={(e) => setAutor(e.target.value)}
            variant="outlined"
          >
            {Autors.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={classes.table}>
              <tr>
                <td>
                  {ArticleData.filter(
                    (obj) =>
                      obj.title.includes(title) &&
                      obj.type.includes(type) &&
                      obj.mainAutor.includes(autor)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((obj, index) => (
                      <Grid
                        key={index}
                        className={classes.grid}
                        container
                        direction="row"
                        justify="space-between"
                      >
                        <Grid item={true} xs={10}>
                          <Button
                            onClick={() => {
                              if (obj.link === "") {
                              } else {
                                window.open(obj.link);
                              }
                            }}
                          >
                            <Typography
                              variant="h6"
                              className={classes.titlteText}
                            >
                              {obj.title}
                            </Typography>
                          </Button>
                          <Typography
                            variant="body1"
                            className={classes.authorText}
                          >
                            Autores: {obj.mainAutor}
                          </Typography>
                          <Grid container direction="row">
                            <Grid
                              item={true}
                              xs={12}
                              sm={3}
                              md={2}
                              lg={1}
                              xl={1}
                            >
                              <Typography variant="body1">
                                Tipo: {obj.type}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                variant="body1"
                                className={classes.dataText}
                              >
                                Data de publicaçao: {obj.data}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item={true}
                          container
                          justify="center"
                          alignItems="center"
                          sm={1}
                          xs={12}
                        >
                          <Button
                            onClick={() => {
                              if (obj.link === "") {
                              } else {
                                window.open(obj.link);
                              }
                            }}
                          >
                            <VisibilityIcon fontSize="large" />
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                </td>
              </tr>
              {emptyRows > 0 && (
                <TableRow style={{ height: 100 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage="Quantidade"
            count={ArticleData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
}
