import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import SearchArticle from "../Components/ArticleFinder/SearchArticle";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  text: {
    padding: "5%",
    textAlign: "justify",
    marginTop: "-2%",
  },
  title: {
    marginTop: "2%",
    fontWeight: "600",
    margin: 20,
  },
  subText: {
    margin: 20,
  },
  body: {
    minHeight: 800,
  },
  background: {
    backgroundColor: "#ffffff",
  },
}));
function Publicacoes() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Header />
      <Grid className={classes.body}>
        <Typography className={classes.title} variant="h3">
          Publicações
        </Typography>
        <Typography variant="h6" className={classes.subText}>
          Aqui você pode conferir todas as publicações produzidas pelos membros
          do nosso laboratório e vale lembrar que o acesso de alguns artigos só
          é possível a partir de pagamento.
        </Typography>
        <SearchArticle />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Publicacoes;
