import React from "react";
import eventos from "./Views/Eventos";
import home from "./Views/Home";
import membros from "./Views/Membros";
import participe from "./Views/Participe";
import publicacoes from "./Views/Publicacoes";
import Softwares from "./Views/Softwares";
import { Switch, Route } from "react-router-dom";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" component={home} exact />
      <Route path="/Eventos" component={eventos} exact />
      <Route path="/Membros" component={membros} exact />
      <Route path="/Participe" component={participe} exact />
      <Route path="/Publicações" component={publicacoes} exact />
      <Route path="/Softwares" component={Softwares} exact />
    </Switch>
  );
};
export default Routes;
