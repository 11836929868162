import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import MemberCard from "../Components/CardMenbros";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  text: {
    padding: "5%",
    textAlign: "justify",
    marginTop: "-2%",
  },
  title: {
    marginTop: "2%",
    fontWeight: "600",
    margin: 20,
  },
  subText: {
    margin: 20,
  },
  body: {
    minHeight: 766,
  },
  card: {
    width: "100%",
  },
  background: {
    backgroundColor: "#ffffff",
  },
}));
function Membros() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Header />
      <Typography className={classes.title} variant="h3">
        Membros
      </Typography>
      <Grid className={classes.body}>
        <Typography className={classes.title} variant="h5">
          Professor
        </Typography>
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Guilherme.jpg")}
              name="Guilherme Menezes Lage"
              desription="Pós-doutorado em Medicina Molecular.Doutor em Neurociências e Mestre em Educação Física"
              interess="Aprendizagem motora e neurociência cognitiva"
              lates="http://lattes.cnpq.br/7291980478992375"
              email="menezeslage@gmail.com"
            />
          </Grid>
        </Grid>
        <Typography className={classes.title} variant="h5">
          Doutores
        </Typography>
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Lidiane.jpg")}
              name="Lidiane Fernandes"
              desription="Doutora e Mestra em Ciências do Esporte pela Universidade Federal de Minas Gerais"
              interess="Aprendizagem e Controle Motor, Atividade Física Adaptada e Ensino da Natação."
              lates=" http://lattes.cnpq.br/5021585349005971"
              email="lidianefernandes12@yahoo.com.br"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Simara.png")}
              name="Simara Ribeiro"
              desription="Doutora em Medicina Molecular e Mestre em Ciências - com ênfase em Ciências da Saúde"
              interess="Comportamento motor de crianças e adolescentes com transtornos do neurodesenvolvimento"
              lates="http://lattes.cnpq.br/9467276610226572"
              email="simararibeiro@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Tercio.jpg")}
              name="Tercio Apolinario-Souza"
              desription="Doutor e Mestre em Ciências do Esporte pela Universidade Federal de Minas Gerais"
              interess="Memória motora, Modelagem matemática e aspectos neuromoleculares do comportamento motor"
              lates="http://lattes.cnpq.br/8921091793681255"
              email="edf.tercio@hotmail.com"
            />
          </Grid>
        </Grid>
        <Typography className={classes.title} variant="h5">
          Doutorandos
        </Typography>
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Barbara.jpg")}
              name="Bárbara Ferreira"
              desription="Doutoranda em Ciencia dos Esportes"
              interess="Diferenças individuais na aprendizagem e controle motor "
              lates=" http://lattes.cnpq.br/5022115131176115"
              email="barbaradepaulaf@gmail.com"
            />
          </Grid>

          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Joana.jpg")}
              name="Joana Pinto"
              desription="Doutoranda em Neurociências"
              interess="Aprendizagem Cognitiva e Motora no Envelhecimento"
              lates="http://lattes.cnpq.br/9365905413674947"
              email="joana.andraderp@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Joao.jpg")}
              name="João Oliveira"
              desription="Doutourando em Ciência dos Esportes"
              interess="Controle motor"
              lates="http://lattes.cnpq.br/2425860983730436"
              email="venturaoliveira.jr@gmail.com"
            />
          </Grid>

          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Leo.jpg")}
              name="Leonardo Lopes"
              desription="Doutorando em Musica"
              interess="Perfomance Musical"
              lates="http://lattes.cnpq.br/7047116773559475"
              email="leodoublebass@gmail.com "
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Lucas.jpg")}
              name="Lucas Bicalho"
              desription="Doutorando em Ciência dos Esportes"
              interess="Aprendizagem Motora"
              lates="http://lattes.cnpq.br/2709274504810351"
              email="bicalho.l@hotmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Lelis.jpg")}
              name="Natália Lelis-Torres"
              desription="Doutoranda em Ciência dos Esportes"
              interess=" Aprendizagem motora, percepção, esforço cognitivo"
              lates=" http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K4336907E9"
              email="natalialelis.torres@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Marinho.png")}
              name="Nathálya Nogueira"
              desription="Doutoranda em Ciência dos Esportes"
              interess="Aspectos psicobiológicos do desempenho humano"
              lates="http://lattes.cnpq.br/9192733496486401"
              email="marinhohnathy@gmail.com"
            />
          </Grid>
        </Grid>
        <Typography className={classes.title} variant="h5">
          Mestras
        </Typography>
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Lilian.jpg")}
              name="Lilian Macedo"
              desription="Mestra em Ciência dos Esportes UFMG"
              interess="Aprendizagem motora"
              lates="http://lattes.cnpq.br/9111954045373255"
              email="liliancmacedo@hotmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Paula.jpg")}
              name="Paula Walker"
              desription="Mestra em Ciência dos Esportes UFMG"
              interess="Aprendizagem Motora e Envelhecimento"
              lates="http://lattes.cnpq.br/4993020018215790"
              email="paulinhawalker@gmail.com"
            />
          </Grid>
        </Grid>
        <Typography className={classes.title} variant="h5">
          Mestrandos
        </Typography>
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Beatriz.jpg")}
              name="Beatriz Couto"
              desription="Mestranda em Neurociências"
              interess="Aprendizagem motora"
              lates="http://lattes.cnpq.br/0569532450811465"
              email="beatrizcfortuna@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Cris.jpg")}
              name="Cristiani Junqueira"
              desription="Mestranda em Ciência dos Esportes"
              interess="Aprendizagem e controle motor de crianças e adolescentes com
              transtornos do neurodesenvolvimento"
              lates="http://lattes.cnpq.br/7779490602666117"
              email="cristianiunqueira74@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Larissa.jpg")}
              name="Larissa Porto"
              desription="Mestranda em Neurociências"
              interess="Desempenho motor e Processamento sensorial"
              lates="http://lattes.cnpq.br/8124794505537868"
              email="to.larissa@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Livia.jpg")}
              name="Lívia Alipio"
              desription="Mestranda em Ciência dos Esportes"
              interess="Aprendizagem e controle motor de crianças e adolescentes com
              transtornos do neurodesenvolvimento"
              lates="http://lattes.cnpq.br/6035947532509862"
              email="liviapenido@yahoo.com.br"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Rafael.jpg")}
              name="Rafael Xavier"
              desription="Mestrando em Ciência dos Esportes"
              interess="Aprendizagem Motora"
              lates="http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K8279767E8"
              email="rafael.sxavier@hotmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Sara.jpeg")}
              name="Sara Leão"
              desription="Mestranda em Neurociências"
              interess="Neuropsicologia"
              lates="http://lattes.cnpq.br/5062839693701278"
              email="sara.assis@sga.pucminas.br"
            />
          </Grid>
        </Grid>
        <Typography className={classes.title} variant="h5">
          Iniciação científica
        </Typography>
        <Grid container justify="center" direction="row" alignItems="center">
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Athos.jpg")}
              name="Athos Borges"
              desription="Graduando em Educaçao Fisica UFMG"
              interess="Diferenças individuais na aprendizagem e controle motor"
              lates="http://lattes.cnpq.br/6286487973384830"
              email="athospborges@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Enzo.jpg")}
              name="Enzo Azevedo"
              desription="Graduando em Educaçao Fisica UFMG"
              interess="Transferencia Bilateral de Aprendiagem"
              lates="http://lattes.cnpq.br/3606032455115595"
              email="enzoaazevedo@gmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Izabelle.jpg")}
              name="Isabelle Nery"
              desription="Graduanda em Educaçao Fisica UFMG"
              interess="Aprendizagem Motora "
              lates="http://lattes.cnpq.br/6969296783553123"
              email="nery_isabelle@hotmail.com"
            />
          </Grid>
          <Grid item={true} lg={6} md={9} sm={12} xs={12}>
            <MemberCard
              picture={require("../Images/Iza.jpg")}
              name="Izabella Sales"
              desription="Graduanda em Educaçao Fisica UFMG"
              interess="Controle motor - propriocepção/cinestesia"
              lates="http://lattes.cnpq.br/7971029735950914"
              email="izabellasalesjn@gmail.com"
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Membros;
