import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import Software from "../Components/Software";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "2%",
    fontWeight: "600",
    margin: 20,
    marginBottom: "0",
  },

  body: {
    display: "flex",
    minHeight: "96vh",
    flexDirection: "column",
  },

  background: {
    backgroundColor: "#ffffff",
  },
}));
function Softwares() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Grid className={classes.body}>
        <Header />
        <Typography className={classes.title} variant="h3">
          Mobile Apps
        </Typography>
        <Software
          title="NNeuromAccel"
          desription="O NneuromAccel é um aplicativo desenvolvido para celular (sistema Android) utilizado para avaliar a aceleração nos três eixos (x, y e z). Você deve definir a frequência (número de pontos por segundo), a duração (em segundos) de cada tentativa e o número de tentativas. Por exemplo, você defini 100 no campo “Frequency”, 1 no campo “Time trial(s)” e 10 no campo “Trials”. Neste exemplo, será adquirido 100 pontos por segundo em cada uma das dez tentativas. Ao final de todas as tentativas aparecerá a tela final com o número de tentativas realizadas e um botão para enviar os dados de cada tentativa. Ao pressionar o botão abrirá uma caixa de diálogo para compartilhar os dados com as mídias instaladas no celular (ex., whatsapp ou e-mail). As tentativas serão enviadas uma a uma! Note, os dados enviados correspondem aos valores de x, y, z e o tempo, de cada “ponto” ao longo das tentativas."
          icon={require("../Images/acelerometro.png")}
          link="https://github.com/edftercio/NNeuromAccel"
        />
        <Software
          title="NNeuromReact"
          desription="O NneuromReact um aplicativo desenvolvido para celular (sistema Android) utilizado para avaliar a o tempo de reação em tarefas discretas. Você deve definir a duração máxima e a sensibilidade do sistema. A sensibilidade é um valor arbitrário, em percentual, definido para indicar o nível de tolerância de aceleração para indicar início de movimento. Em outras palavras, o valor indicado no campo “sensitivity (0 – 100%)” indicará o percentual de variação permitido para que considere início do movimento."
          icon={require("../Images/time.png")}
          link="https://github.com/edftercio/NNeuromReact"
        />
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Softwares;
