import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const Software = ({ title, desription, link, icon }) => (
  <React.Fragment>
    <Grid container direction="row" alignItems="center" style={{ padding: 10 }}>
      <Grid
        item={true}
        lg={2}
        md={3}
        sm={4}
        xs={12}
        container
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Typography
          style={{
            textAlign: "justify",
            fontWeight: 600,
            paddingBottom: 10,
          }}
          variant="h5"
        >
          {title}
        </Typography>
        <img src={icon} style={{ width: "150px" }} />
      </Grid>
      <Grid
        item={true}
        lg={10}
        xs={12}
        sm={8}
        md={9}
        style={{ padding: 10, paddingTop: 40 }}
      >
        <Typography style={{ textAlign: "justify" }} variant="subtitle1">
          {desription}
        </Typography>
        <Grid item={true}>
          <Button
            onClick={() => {
              window.open(link);
            }}
          >
            <Typography
              style={{ fontWeight: "600", marginLeft: -8, marginTop: 5 }}
            >
              download
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
);
export default Software;
