import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#ffffff",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: 15,
  },
  texttitle: {
    color: "#ffffff",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: 19,
  },
}));
export default function Header() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Box>
        <Typography variant="subtitle2">
          UNIVERSIDADE FEDERAL DE MINAS GERAIS
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ backgroundColor: "#D21819", minHeight: "5em" }}
        >
          <Grid
            item={true}
            container
            direction="row"
            justify="center"
            alignItems="center"
            sm={2}
            md={2}
            xs={12}
            lg={1}
          >
            <Link to="/" className={classes.texttitle}>
              NNeuroM
            </Link>
          </Grid>

          <Grid
            item={true}
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            sm={9}
            md={7}
            lg={5}
          >
            <Button>
              <Link className={classes.text} to="/Publicações">
                Publicações
              </Link>
            </Button>
            <Button>
              <Link className={classes.text} to="/Membros">
                Membros
              </Link>
            </Button>
            <Button>
              <Link className={classes.text} to="/Eventos">
                Eventos
              </Link>
            </Button>
            <Button>
              <Link className={classes.text} to="/Participe">
                Participe
              </Link>
            </Button>
            <Button>
              <Link className={classes.text} to="/Softwares">
                Softwares
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
