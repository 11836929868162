import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#ffffff",
    fontWeight: "600",
  },
  gridFooter: {
    backgroundColor: "#980000",
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid className={classes.gridFooter}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="subtitle1" className={classes.text}>
            EEFFTO - Escola de Educação Física, Fisioterapia e Terapia
            Ocupacional
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            Av. Pres. Antônio Carlos, 6627 Campus - Pampulha - Belo Horizonte -
            MG - CEP 31270-901
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            UFMG - Universidade Federal de Minas Gerais
          </Typography>
          <Typography variant="subtitle1" className={classes.text}>
            Desenvolvido por Enzo Azevedo
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
