import React from "react";
import Header from "../Components/Header";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Footer from "../Components/Footer";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainTitle: { fontWeight: "600", margin: 20, marginBottom: "0" },
  title: {
    fontWeight: "600",
    margin: 20,
  },
  image: {
    width: "100%",
  },
  text: {
    textAlign: "justify",
    margin: 14,
    fontSize: 21,
  },
  background: {
    backgroundColor: "#ffffff",
  },
}));
function Participe() {
  const classes = useStyles();
  return (
    <Grid className={classes.background}>
      <Header />
      <Typography className={classes.mainTitle} variant="h3">
        Participe
      </Typography>
      <Typography variant="h5" className={classes.title}>
        Reuniões do laboratório
      </Typography>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item={true} xs={8} md={3} sm={7}>
          <img
            src={require("../Images/Reuniao.png")}
            className={classes.image}
          />
        </Grid>
        <Grid item={true} xs={12} md={9}>
          <Typography variant="subtitle1" className={classes.text}>
            As reuniões do laboratório ocorrem às sextas-feiras de 13:30 às 17
            horas. A partir de 2021 faremos uma reunião aberta ao público na
            última sexta-feira de cada mês.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h5" className={classes.title}>
        Pós-graduação
      </Typography>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item={true} xs={8} md={3} sm={7}>
          <img src={require("../Images/Ic.png")} className={classes.image} />
        </Grid>
        <Grid item={true} xs={12} md={9}>
          <Typography variant="subtitle1" className={classes.text}>
            Alunos do NNeurom são orientados a nível mestrado, doutorado e
            pós-doutorado nos Programas de Pós-graduação da UFMG em Ciências do
            Esporte (PPGCE), Neurociências e Música.
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="h5" className={classes.title}>
        Iniciação científica
      </Typography>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item={true} xs={8} md={3} s m={7}>
          <img
            src={require("../Images/Mestrado.png")}
            className={classes.image}
          />
        </Grid>
        <Grid item={true} xs={12} md={9}>
          <Typography variant="subtitle1" className={classes.text}>
            Programa de preparação de alunos de graduação para a futura inserção
            na Pós-graduação, nível mestrado.
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default Participe;
